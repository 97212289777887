import React from 'react';
import { Helmet } from "react-helmet";
import TemplateWrapper from '../components/layout';

const posts = [
    '/img/photos/portfolio/1.jpg',
    '/img/photos/portfolio/2.jpg',
    '/img/photos/portfolio/2a.jpg',
    '/img/photos/portfolio/3.jpg',
    '/img/photos/portfolio/4.jpg',
    '/img/photos/portfolio/5.jpg',
    '/img/photos/portfolio/5a.jpg',
    '/img/photos/portfolio/6.jpg',
    '/img/photos/portfolio/7.jpg',
    '/img/photos/portfolio/8.jpg',
    '/img/photos/portfolio/9.jpg',
    '/img/photos/portfolio/10.jpg',
    '/img/photos/portfolio/11.jpg',
    '/img/photos/portfolio/12.jpg',
    '/img/photos/portfolio/13.jpg',
    '/img/photos/portfolio/14.jpg',
    '/img/photos/portfolio/15.jpg',
    '/img/photos/portfolio/16.jpg',
    '/img/photos/portfolio/17.jpg',
    '/img/photos/portfolio/18.jpg',
    '/img/photos/portfolio/21.jpg',
    '/img/photos/portfolio/22.jpg',
    '/img/photos/portfolio/23.jpg',
    '/img/photos/portfolio/24.jpg',
    '/img/photos/portfolio/25.jpg',
    '/img/photos/portfolio/26.jpg',
    '/img/photos/portfolio/27.jpg',
    '/img/photos/portfolio/28.jpg',
    '/img/photos/portfolio/29.jpg',
    '/img/photos/portfolio/30.jpg',
    '/img/photos/portfolio/31.jpg',
    '/img/photos/portfolio/32.jpg',
    '/img/photos/portfolio/33.jpg',
    '/img/photos/portfolio/34.jpg',

];

const images = posts.map((c, index) => {
    return (
        <img
            className="col-md-4"
            alt="Наши работы"
            key={index}
            src={c} />
    )
})

const NashiRaboty = () => (
  <TemplateWrapper>
    <div className="article-content">
        <Helmet>
            <title>Наши работы - hair.moscow</title>
            <meta name="description" content="Галерея работ лучших мастеров по наращиванию и окрашиванию волос в Москве!" />
            <meta name="keywords" content="галерея, фото наращивания, смотреть нарощенные волосы, покажите волосы, красивые волосы" />
        </Helmet>
        <h1 className="hm-title-1">Наши работы</h1>
        <div className="row">
            {images}
        </div>
    </div>
    </TemplateWrapper>
)

export default NashiRaboty